// TODO: ADD redux to save orders that still needs to be printed
// TODO: ADD Printing status of orders

export const printReceipt = (
  printer,
  order,
  imgContext,
  showPrintAlert,
  etd,
  restaurant,
  uniqueCode = "",
  isHeader = false
) => {
  const prt = printer.current;
  if (prt) {
    if (isHeader) {
      // IMAGE

      prt.addImage(imgContext, 0, 0, 500, 200);

      // HEADING
      prt.addTextAlign(prt.ALIGN_CENTER);
      prt.addTextStyle(false, false, true, prt.COLOR_1);
      prt.addText("Den Box\n");
      prt.addTextStyle(false, false, false, prt.COLOR_1);

      if (restaurant === "asse") {
        prt.addText("Benedenstraat 6\n");
        prt.addText("1730 Asse\n");
        prt.addText("02 669 98 32\n");
      } else {
        prt.addText("Muilenstraat 92A\n");
        prt.addText("1770 Liedekerke\n");
        prt.addText("053 42 64 33\n");
      }

      prt.addFeedLine(1);
      prt.addText(`${order.created.toDate().toLocaleString("nl-BE")}\n`);
      prt.addFeedLine(1);

      // WEDSTRIJD CODE
      if (uniqueCode !== "") {
        prt.addFeedLine(1);
        prt.addText("________________________________________________\n");
        prt.addTextAlign(prt.ALIGN_CENTER);
        prt.addText("Wedstrijdcode:  \n");
        prt.addFeedLine(1);
        prt.addTextDouble(true, true);
        prt.addText(`${uniqueCode} \n`);
        prt.addTextDouble(false, false);
        prt.addFeedLine(1);
        prt.addText("Verzilver deze code op frietekes.be, \n");
        prt.addText("en maak kans op een reischeque van 1.000€! \n");
        prt.addTextAlign(prt.ALIGN_LEFT);
        prt.addText("________________________________________________\n");
        prt.addFeedLine(1);
      }
    }
    // GEGEVENS
    prt.addTextAlign(prt.ALIGN_LEFT);
    prt.addText(`${order.firstName} ${order.lastName}\n`);
    if (order.delivery) {
      prt.addFeedLine(1);
      prt.addTextDouble(true, false);
      prt.addText(`${order.street} ${order.number} ${order.bus}\n`);
      prt.addText(`${order.postalCode} ${order.city}\n`);
      prt.addText(`${order.telephone}\n`);
      prt.addFeedLine(1);

      prt.addTextDouble(false, true);
      if (order.delivery) {
        prt.addText("Leveren");
      } else {
        prt.addText("Afhalen");
      }
      prt.addTextStyle(false, false, true, prt.COLOR_1);
      if (order.time === "asap") {
        prt.addText(
          ` zo snel mogelijk (circa ${order.etd ? order.etd : etd}) \n`
        );
      } else {
        prt.addText(` om ${order.time} \n`);
      }

      if (isHeader) {
        prt.addFeedLine(1);
        const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${order.street}+${order.number}%2C+${order.postalCode}+${order.city}`;

        prt.addSymbol(mapsUrl, prt.SYMBOL_QRCODE_MODEL_2, prt.LEVEL_DEFAULT, 5);
      } else {
        prt.addText(`${order.telephone}\n`);
      }

      prt.addFeedLine(1);
    }

    prt.addTextStyle(false, false, false, prt.COLOR_1);
    prt.addTextDouble(false, false);
    prt.addFeedLine(1);
    prt.addTextLineSpace(40);

    prt.addText("________________________________________________\n");

    let previousCategory = 0;
    order.items.forEach((it) => {
      prt.addTextStyle(false, false, true, prt.COLOR_1);
      // NEWLINE AFTER FRIETEN

      if (
        (previousCategory === 10 || previousCategory === 11) &&
        it.categoryOrder !== 11 &&
        previousCategory !== it.categoryOrder
      ) {
        prt.addText("\n");
        prt.addText("\n");
      }
      previousCategory = it.categoryOrder;

      // QTY
      const qtyStr = `${it.qty}x`;
      if (qtyStr.length < 3) {
        prt.addText(" ");
      }
      prt.addText(`${it.qty}x`);
      prt.addText("   ");

      // NAME
      prt.addTextStyle(false, false, true, prt.COLOR_1);
      prt.addText(`${it.name}`);
      const totalSpaces = 32 - it.name.length;
      for (let index = 0; index < totalSpaces; index++) {
        prt.addText(" ");
      }

      // PRICE
      prt.addTextStyle(false, false, false, prt.COLOR_1);
      const priceStr = `${((it.qty * it.price) / 100)
        .toFixed(2)
        .replace(".", ",")}€`;
      const totalPriceSpaces = 10 - priceStr.length;
      for (let index = 0; index < totalPriceSpaces; index++) {
        prt.addText(" ");
      }
      prt.addText(
        `${((it.qty * it.price) / 100).toFixed(2).replace(".", ",")}€\n`
      );

      // SIZE
      if (it.size) {
        prt.addText("        ");
        prt.addText(`- ${it.size}\n`);
      }

      // EXTRAS
      if (it.extras.length) {
        it.extras.forEach((ex) => {
          prt.addText("        ");
          prt.addText(`- ${ex.name}\n`);
        });
      }
    });

    if (order.freeProduct) {
      prt.addText("\n");
      prt.addText("      ");
      prt.addTextStyle(false, false, true, prt.COLOR_1);
      prt.addText(`Gratis ${order.freeProduct}\n`);
      prt.addTextStyle(false, false, true, prt.COLOR_1);
    }

    prt.addText("________________________________________________\n");
    prt.addFeedLine(1);

    prt.addTextDouble(true, false);
    // COUPON

    if (order.coupon) {
      // PRICE
      prt.addTextAlign(prt.ALIGN_RIGHT);
      prt.addText(
        `${((order.totalPrice + order.coupon.amount) / 100)
          .toFixed(2)
          .replace(".", ",")}€\n`
      );

      const couponStrL = order.coupon.label.length;
      const neededSpaces = 16 - couponStrL;
      prt.addText(order.coupon.label.toUpperCase());
      for (let index = 0; index < neededSpaces; index++) {
        prt.addText(" ");
      }

      const couponStr = `${(-order.coupon.amount / 100)
        .toFixed(2)
        .replace(".", ",")}€`;
      const spacesToAdd = 8 - couponStr.length;

      for (let index = 0; index < spacesToAdd; index++) {
        prt.addText(" ");
      }

      prt.addText(
        `${(-order.coupon.amount / 100).toFixed(2).replace(".", ",")}€\n`
      );
    }
    // PRICE
    prt.addTextAlign(prt.ALIGN_RIGHT);
    prt.addText(`${(order.totalPrice / 100).toFixed(2).replace(".", ",")}€\n`);

    // PAYMENT
    prt.addTextDouble(false, false);
    if (order.payment === "online") {
      prt.addTextStyle(false, false, true, prt.COLOR_1);
      prt.addText("Online betaald");
      prt.addTextStyle(false, false, false, prt.COLOR_1);
    }

    // COMMENTS
    if (order.comment) {
      prt.addFeedLine(1);
      prt.addTextAlign(prt.ALIGN_LEFT);
      prt.addTextDouble(false, true);
      prt.addText(`${order.comment}\n`);
      prt.addTextDouble(false, false);
    }

    // END
    prt.addFeedLine(2);
    prt.addTextAlign(prt.ALIGN_CENTER);
    prt.addText("Bedankt en smakelijk!\n");

    prt.addFeedLine(3);
    prt.addCut(prt.CUT_FEED);

    prt.send();

    // Callback print result
    prt.onreceive = function (res) {
      if (!res.success) {
        showPrintAlert(
          `${order.street} ${order.number} ${order.bus}, ${order.postalCode} ${order.city} werd niet geprint!`
        );
      }
    };
  }
};

export const openDrawer = (printer) => {
  const prt = printer.current;
  if (prt) {
    prt.addPulse(prt.DRAWER_1, prt.PULSE_200);
    prt.send();
  }
};

export const printCoupons = (printer, coupons) => {
  const prt = printer.current;
  if (prt) {
    coupons.forEach((c) => {
      prt.addText(
        `${c.label} - ${(c.amount / 100).toFixed(2).replace(".", ",")}€\n`
      );
    });

    prt.addFeedLine(3);
    prt.addCut(prt.CUT_FEED);

    prt.send();
  }
};

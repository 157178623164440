import { Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import ProtectedRoute from "./navigation/ProtectedRoute";
import HomeScreen from "./screens/HomeScreen";
import InventoryScreen from "./screens/InventoryScreen";
import LoginScreen from "./screens/LoginScreen";
import SettingsScreen from "./screens/SettingsScreen";
import IncomeScreen from "./screens/IncomeScreen";
import CouponScreen from "./screens/CouponScreen";

function App() {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route
            path="/"
            element={
              <>
                <Header />
                <HomeScreen />
              </>
            }
          />
          <Route
            path="/inventory"
            element={
              <>
                <Header />
                <InventoryScreen />
              </>
            }
          />
          <Route
            path="/incomes"
            element={
              <>
                <Header />
                <IncomeScreen />
              </>
            }
          />
          <Route
            path="/coupons"
            element={
              <>
                <Header />
                <CouponScreen />
              </>
            }
          />
          <Route
            path="/settings"
            element={
              <>
                <Header />
                <SettingsScreen />
              </>
            }
          />
        </Route>
        <Route path="/login" element={<LoginScreen />} />
      </Routes>
    </div>
  );
}

export default App;

import {
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  TextField,
  Typography,
} from "@mui/material";
import { doc, getDoc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../utils/firebase";
import { useAuth } from "../contexts/AuthContext";

import * as moment from "moment";
import { grey } from "@mui/material/colors";

import takeawayLogo from "../assets/images/takeaway.png";
import denboxLogo from "../assets/images/logo.png";
import { Add, CalendarToday } from "@mui/icons-material";
import { useOrders } from "../contexts/OrdersContext";

moment.locale("nl-BE");

const monthNames = [
  "januari",
  "februari",
  "maart",
  "april",
  "mei",
  "juni",
  "juli",
  "augustus",
  "september",
  "oktober",
  "november",
  "december",
];

const IncomeScreen = () => {
  const { restaurant } = useAuth();
  const [incomes, setIncomes] = useState({ days: [] });

  const [takeaway, setTakeaway] = useState("");

  const { totalPrice } = useOrders();

  const thisMonth = moment().month() + 1;
  const thisYear = moment().year();
  const uniqueId = `${thisMonth}${thisYear}`;

  const sortedDays = [...incomes.days.sort((a, b) => b.id - a.id)];

  useEffect(() => {
    const getIncomes = async () => {
      try {
        const docRef = doc(db, `restaurants/${restaurant}/incomes/${uniqueId}`);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setIncomes(docSnap.data());
        }
      } catch (error) {
        console.log(error);
      }
    };

    getIncomes();
  }, [restaurant, uniqueId]);

  const addIncome = async () => {
    const today = moment().date();

    const newIcome = {
      id: today,
      takeaway: Number.parseFloat(takeaway) * 100,
      takeawayonline: Number.parseFloat(takeaway) * 100,
      web: totalPrice,
      webonline: totalPrice,
    };

    let newIncomes = [];
    const days = incomes.days;

    const foundIdx = days.findIndex((d) => d.id === today);

    if (foundIdx > -1) {
      days[foundIdx] = newIcome;
      newIncomes = [...days];
    } else {
      newIncomes = [...days, newIcome];
    }

    const docRef = doc(db, `restaurants/${restaurant}/incomes/${uniqueId}`);

    try {
      await setDoc(docRef, { days: newIncomes }, { merge: true });
      setTakeaway("");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ px: 4, width: "100%" }}>
      <Typography sx={{ fontWeight: "lighter", mb: 2 }} variant="h6">
        INKOMSTEN
      </Typography>

      <Box
        sx={{
          borderColor: grey[300],
          borderWidth: 1,
          borderStyle: "solid",
          p: 4,
          mt: 4,
        }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography>TOEVOEGEN</Typography>
          <Icon>
            <Add />
          </Icon>
        </Box>
        <Divider />
        <Box sx={{ my: 2, display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <CalendarToday
              sx={{ color: "action.active", width: 30 }}
              style={{ marginRight: "16px", marginBottom: "16px" }}
            />
            <TextField
              fullWidth
              label="Datum"
              disabled
              value={moment().date()}
              variant="standard"
              margin="normal"
              type="number"
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <img
              src={denboxLogo}
              alt="denboxlogo"
              style={{
                width: 30,
                marginRight: "16px",
                marginBottom: "16px",
              }}
            />
            <TextField
              fullWidth
              label="Website"
              disabled
              value={(totalPrice / 100).toFixed(2)}
              variant="standard"
              margin="normal"
              type="number"
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <img
              src={takeawayLogo}
              alt="takeawaylogo"
              style={{
                width: 30,
                marginRight: "16px",
                marginBottom: "16px",
              }}
            />

            <TextField
              fullWidth
              label="Takeaway"
              value={takeaway}
              onChange={(e) => setTakeaway(e.target.value)}
              type="number"
              variant="standard"
              margin="normal"
            />
          </Box>
          <Button
            sx={{ mt: 4, ml: "auto" }}
            variant="contained"
            onClick={addIncome}>
            Opslaan
          </Button>
        </Box>
      </Box>

      <Grid container spacing={2} sx={{ my: 2 }}>
        {sortedDays.map((d) => (
          <Grid xs={12} md={4} xl={3} item key={d.id}>
            <Box
              sx={{
                p: 2,
                borderRadius: 5,
                borderColor: grey[500],
                borderWidth: "2px",
                borderStyle: "solid",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}>
              <Typography sx={{ fontWeight: "bold", fontSize: 20 }} key={d.id}>
                {d.id} {monthNames[moment().month()]}
              </Typography>
              <Box
                sx={{
                  mt: 2,
                  mb: 1,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <img src={denboxLogo} style={{ width: 30 }} alt="takeaway" />
                <Typography sx={{ fontWeight: "700" }}>
                  {(d.web / 100).toFixed(2).replace(".", ",")} €
                </Typography>
              </Box>
              <Box
                sx={{
                  my: 1,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <img src={takeawayLogo} style={{ width: 30 }} alt="takeaway" />
                <Typography sx={{ fontWeight: "700" }}>
                  {(d.takeaway / 100).toFixed(2).replace(".", ",")} €
                </Typography>
              </Box>
              <Box
                sx={{
                  my: 1,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <Typography sx={{ fontWeight: "light" }}>TOTAAL</Typography>

                <Typography sx={{ fontWeight: "700" }}>
                  {((d.takeaway + d.web) / 100).toFixed(2).replace(".", ",")} €
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default IncomeScreen;

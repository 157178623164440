import {
  AccessTime,
  Person3Outlined,
  PrintOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Icon,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { usePrinter } from "../contexts/PrinterContext";
import { auth } from "../utils/firebase";
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import { db } from "../utils/firebase";

// import voucherCode from "voucher-code-generator";

const SettingsScreen = () => {
  const { logout } = useAuth();

  const { printerConfig, setPrinterConfig } = usePrinter();

  // const [discount, setDiscount] = useState(0);
  const [ip, setIp] = useState(printerConfig.ip);
  const [prints, setPrints] = useState(printerConfig.prints);

  const [hours, setHours] = useState([]);
  const { restaurant } = useAuth();

  const handleSave = () => {
    setPrinterConfig({
      ip: ip,
      prints: prints,
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const q = query(
          collection(db, `restaurants/${restaurant}/hours`),
          orderBy("day")
        );
        const result = await getDocs(q);

        const docs = result.docs.map((ds) => ({ id: ds.id, ...ds.data() }));

        setHours(docs);
      } catch (error) {
        console.log("Hours error: ", error);
      }
    })();
  }, [restaurant]);

  const handleChangeIsOpen = async (event) => {
    try {
      const foundHour = hours.find((ho) => ho.id === event.target.name);

      const docRef = doc(
        db,
        `restaurants/${restaurant}/hours/${event.target.name}`
      );

      await updateDoc(docRef, {
        isOpen: event.target.checked,
      });

      if (foundHour) {
        setHours(
          [
            ...hours.filter((h) => h.id !== event.target.name),
            { ...foundHour, isOpen: !foundHour.isOpen },
          ].sort((a, b) => a.day - b.day)
        );
      }
    } catch (error) {
      console.log("Hours Update error: ", error);
    }
  };

  // const newCoupon = voucherCode.generate({
  //   length: 8,
  //   charset: "abcdefghijklmnpqrstuvwxyz123456789",
  // });

  return (
    <Box sx={{ px: 4, userSelect: "none" }}>
      <Typography sx={{ fontWeight: "lighter", mb: 2 }} variant="h6">
        INSTELLINGEN
      </Typography>
      <Box
        sx={{
          borderColor: grey[300],
          borderWidth: 1,
          borderStyle: "solid",
          p: 4,
          mt: 4,
        }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography>OPENING</Typography>
          <Icon>
            <AccessTime />
          </Icon>
        </Box>
        <Divider />

        <Box sx={{ my: 2, display: "flex", flexDirection: "column", gap: 4 }}>
          {hours
            .filter((h) => h.blocks != null)
            .map((h) => (
              <Box
                key={h.id}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 4,
                }}>
                <Typography sx={{ fontWeight: "light" }}>{h.label}</Typography>
                <Switch
                  name={h.id}
                  checked={hours && hours.find((ho) => ho.id === h.id).isOpen}
                  onChange={handleChangeIsOpen}
                  size="large"
                />
              </Box>
            ))}
        </Box>
      </Box>
      {/* <Box
        sx={{
          borderColor: grey[300],
          borderWidth: 1,
          borderStyle: "solid",
          p: 4,
          mt: 4,
        }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography>COUPONS</Typography>
          <Icon>
            <DiscountOutlined />
          </Icon>
        </Box>
        <Divider />
        <Box sx={{ my: 2, display: "flex", flexDirection: "column" }}>
          <TextField
            fullWidth
            label="Code"
            name="code"
            value={newCoupon}
            variant="standard"
            margin="normal"
            disabled
          />
          <TextField
            fullWidth
            label="Korting"
            name="discount"
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
            variant="standard"
            margin="normal"
            type="number"
          />
          <TextField
            fullWidth
            label="Aantal tickets"
            name="qty"
            value={prints}
            onChange={(e) => setPrints(e.target.value)}
            type="number"
            variant="standard"
            margin="normal"
          />

          <Button
            sx={{ mt: 4, ml: "auto" }}
            variant="contained"
            onClick={handleSave}>
            Nieuwe coupon
          </Button>
        </Box>
      </Box> */}
      <Box
        sx={{
          borderColor: grey[300],
          borderWidth: 1,
          borderStyle: "solid",
          p: 4,
          mt: 4,
        }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography>PRINTER</Typography>
          <Icon>
            <PrintOutlined />
          </Icon>
        </Box>
        <Divider />
        <Box sx={{ my: 2, display: "flex", flexDirection: "column" }}>
          <TextField
            fullWidth
            label="IP adres"
            name="ipAddress"
            value={ip}
            onChange={(e) => setIp(e.target.value)}
            variant="standard"
            margin="normal"
          />
          <TextField
            fullWidth
            label="Aantal tickets"
            name="qty"
            value={prints}
            onChange={(e) => setPrints(e.target.value)}
            type="number"
            variant="standard"
            margin="normal"
          />

          {(printerConfig.ip !== ip ||
            Number.parseInt(printerConfig.prints) !== prints) && (
            <Button
              sx={{ mt: 4, ml: "auto" }}
              variant="contained"
              onClick={handleSave}>
              Opslaan
            </Button>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          borderColor: grey[300],
          borderWidth: 1,
          borderStyle: "solid",
          p: 4,
          mt: 4,
        }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography>GEBRUIKER</Typography>
          <Icon>
            <Person3Outlined />
          </Icon>
        </Box>
        <Divider />
        <Box sx={{ my: 2, display: "flex", flexDirection: "column", gap: 4 }}>
          <Typography>{auth?.currentUser?.email}</Typography>
          <Button
            sx={{ alignSelf: "flex-end" }}
            variant="contained"
            onClick={logout}>
            Uitloggen
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsScreen;

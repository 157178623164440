import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./utils/muiTheme";
import { PrinterContextProvider } from "./contexts/PrinterContext";
import { AuthContextProvider } from "./contexts/AuthContext";
import { OrdersContextProvider } from "./contexts/OrdersContext";
import { SnackbarProvider } from "notistack";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <CssBaseline>
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={5}>
        <BrowserRouter>
          <AuthContextProvider>
            <PrinterContextProvider>
              <OrdersContextProvider>
                <App />
              </OrdersContextProvider>
            </PrinterContextProvider>
          </AuthContextProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  </CssBaseline>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

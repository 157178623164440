import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ConfirmDialog from "../components/ConfirmDialog";

import * as moment from "moment";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../utils/firebase";

import logo from "../assets/images/logo_print.png";
import { useSnackbar } from "notistack";
import { useAuth } from "./AuthContext";

const OrdersContext = createContext();

moment.locale("nl-BE");

export const OrdersContextProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);
  const [imgContext, setImgContext] = useState();

  const { restaurant } = useAuth();

  const canvasRef = useRef();

  const { enqueueSnackbar } = useSnackbar();

  const showPrintAlert = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 20000,
      anchorOrigin: { horizontal: "center", vertical: "top" },
    });
  };

  useEffect(() => {
    // const today = moment("2024-02-15");
    const today = moment();

    const start = today.startOf("day").toDate();
    const end = today.endOf("day").toDate();

    const q = query(
      collection(db, `restaurants/${restaurant}/orders`),
      where("created", ">=", start),
      where("created", "<", end),
      where("status", "in", ["new", "confirmed"]),
      orderBy("created", "desc")
    );

    const unsubscribe = onSnapshot(q, (qs) => {
      const data = qs.docs.map((ds) => ({
        ...ds.data(),
        id: ds.id,
        ref: ds.ref,
      }));
      setOrders(data);
    });

    if (canvasRef.current) {
      let context = canvasRef.current.getContext("2d", {
        willReadFrequently: true,
      });
      context.clearRect(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );

      const image = new Image();
      image.src = logo;

      image.onload = () => {
        context.drawImage(image, 200, 0);
        setImgContext(context);
      };
    }

    return unsubscribe;
  }, [restaurant]);

  const newOrders = orders.filter((o) => o.status === "new");
  const sortedOrders = orders
    .filter((o) => o.status === "confirmed")
    .sort((a, b) => {
      if (a.time > b.time) {
        return -1;
      } else if (a.time < b.time) {
        return 1;
      }

      if (a.time === "asap" && b.time === "asap" && a.etd > b.etd) {
        return -1;
      } else if (a.time === "asap" && b.time && a.etd < b.etd) {
        return 1;
      }

      return 0;
    });

  const lastSortedOrders = orders
    .filter((o) => o.status === "confirmed")
    .sort((a, b) => {
      if (a.created > b.created) {
        return -1;
      } else if (a.created < b.created) {
        return 1;
      }

      return 0;
    });

  const totalPrice = sortedOrders.reduce((acc, val) => acc + val.totalPrice, 0);

  return (
    <OrdersContext.Provider
      value={{
        sortedOrders: sortedOrders,
        lastSortedOrders: lastSortedOrders,
        newOrders: newOrders,
        showPrintAlert: showPrintAlert,
        imgContext: imgContext,
        totalPrice: totalPrice,
      }}>
      <canvas
        style={{ display: "none", width: "800px" }}
        width="500px"
        ref={canvasRef}></canvas>
      <ConfirmDialog
        imgContext={imgContext}
        showPrintAlert={showPrintAlert}
        open={newOrders.length > 0}
        orders={newOrders}
      />
      {children}
    </OrdersContext.Provider>
  );
};

export const useOrders = () => useContext(OrdersContext);

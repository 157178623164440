import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, serverTimestamp } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDAZBZXUWA0iRNrDRglgyMTeT9R6YeypEY",
  authDomain: "denbox-adf7b.firebaseapp.com",
  databaseURL: "https://denbox-adf7b.firebaseio.com",
  projectId: "denbox-adf7b",
  storageBucket: "denbox-adf7b.appspot.com",
  messagingSenderId: "11119219783",
  appId: "1:11119219783:web:249948cea1f8b13e930d62",
  measurementId: "G-1YN4K6L8T2",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app, "europe-west1");
export const firestoreTimeStamp = serverTimestamp;

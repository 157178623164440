import {
  Box,
  Button,
  Divider,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  writeBatch,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";

import voucherCode from "voucher-code-generator";
import { db } from "../utils/firebase";
import { grey, red } from "@mui/material/colors";
import { Add, DeleteOutline, PrintOutlined } from "@mui/icons-material";
import { printCoupons } from "../utils/printReceipt";
import { usePrinter } from "../contexts/PrinterContext";

const CouponScreen = () => {
  const [coupons, setCoupons] = useState([]);
  const [times, setTimes] = useState(1);
  const [amount, setAmount] = useState("");
  const [qty, setQty] = useState(1);

  const [recentCoupons, setRecentCoupons] = useState([]);

  const { printer } = usePrinter();

  useEffect(() => {
    const q = query(collection(db, "coupons"));

    const unsubscribe = onSnapshot(q, (qs) => {
      const cps = qs.docs.map((ds) => ({ id: ds.id, ...ds.data() }));
      setCoupons(cps);
    });

    return unsubscribe;
  }, []);

  const generateCodes = async () => {
    const codes = voucherCode.generate({
      length: 8,
      count: Number.parseInt(qty),
      charset: "abcdefghijklmnpqrstuvwxyz123456789",
    });

    try {
      const batch = writeBatch(db);

      const newCoupons = codes.map((c) => ({
        label: c,
        amount: Number.parseFloat(amount) * 100,
        times: Number.parseInt(times),
      }));

      newCoupons.forEach((c) => {
        const couponRef = doc(db, "coupons", c.label);
        batch.set(couponRef, { ...c });
      });

      await batch.commit();
      setRecentCoupons(newCoupons);
      resetForm();
    } catch (error) {
      console.log(error);
    }
  };

  const resetForm = () => {
    setAmount("");
    setTimes(1);
    setQty(1);
  };

  const deleteCoupon = async (id) => {
    try {
      await deleteDoc(doc(db, "coupons", id));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ px: 4, width: "100%" }}>
      <Typography sx={{ fontWeight: "lighter", mb: 2 }} variant="h6">
        COUPONS
      </Typography>

      <Box
        sx={{
          borderColor: grey[300],
          borderWidth: 1,
          borderStyle: "solid",
          p: 4,
          mt: 4,
        }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography>TOEVOEGEN</Typography>
          <Icon>
            <Add />
          </Icon>
        </Box>
        <Divider />
        <Box sx={{ my: 2, display: "flex", flexDirection: "column" }}>
          <TextField
            fullWidth
            label="Maximaal aantal keer"
            value={times}
            onChange={(e) => setTimes(e.target.value)}
            variant="standard"
            margin="normal"
            type="number"
          />

          <TextField
            fullWidth
            label="Bedrag"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            variant="standard"
            margin="normal"
            type="number"
          />

          <TextField
            fullWidth
            label="Hoeveelheid coupons"
            value={qty}
            onChange={(e) => setQty(e.target.value)}
            type="number"
            variant="standard"
            margin="normal"
          />

          <Button
            sx={{ mt: 4, ml: "auto" }}
            variant="contained"
            onClick={generateCodes}>
            Opslaan
          </Button>
        </Box>
      </Box>

      {recentCoupons.length !== 0 && (
        <>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              my: 2,
            }}>
            <Typography sx={{ fontWeight: "light", fontSize: 16, mb: 2 }}>
              RECENT TOEGEVOEGDE COUPONS:
            </Typography>

            {recentCoupons.map((c) => (
              <Typography key={c.label} sx={{ fontWeight: "700" }}>
                {c.label}
              </Typography>
            ))}
            <IconButton
              sx={{ alignSelf: "flex-end" }}
              onClick={() => printCoupons(printer, recentCoupons)}>
              <PrintOutlined />
            </IconButton>
          </Box>

          <Divider />
        </>
      )}
      <Typography sx={{ fontWeight: "light", fontSize: 16, mt: 2 }}>
        COUPONS:
      </Typography>
      {coupons.length ? (
        <TableContainer sx={{ my: 2 }}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Label</TableCell>
                <TableCell align="center">Bedrag</TableCell>
                <TableCell align="center">Resterend</TableCell>
                <TableCell align="center">Resterend</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {coupons.map((c) => (
                <TableRow
                  key={c.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}>
                  <TableCell>{c.label}</TableCell>
                  <TableCell align="center">
                    {(c.amount / 100).toFixed(2).replace(".", ",")}€
                  </TableCell>
                  <TableCell align="center">{c.times}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      sx={{ color: red[600] }}
                      onClick={() => deleteCoupon(c.id)}>
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography
          align="center"
          fontWeight={700}
          fontSize={20}
          sx={{ my: 2 }}>
          GEEN COUPONS
        </Typography>
      )}
    </Box>
  );
};

export default CouponScreen;

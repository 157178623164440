import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../utils/firebase";
import { useSnackbar } from "notistack";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [restaurant, setRestaurant] = useState();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setRestaurant(
          user.email === "asse@frietbestellen.be" ? "asse" : "liedekerke"
        );
      } else if (!user) {
        setUser(null);
      } else {
        setUser(undefined);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const login = async (data) => {
    try {
      const result = await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      if (result.user) {
        navigate("/");
      }
    } catch (error) {
      enqueueSnackbar(`Fout bij het inloggen: ${error?.message}`, {
        variant: "error",
      });
    }
  };

  const logout = async () => {
    await signOut(auth);
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, restaurant }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
